import React from "react";
import { SiteTitle } from "../components/elements/SiteTitle";

export class Error extends React.Component {
  render() {
    return (
      <main>
        <SiteTitle>Inspire Group</SiteTitle>
      </main>
    );
  }
}

export default Error;
